<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.voucherNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Form Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.formNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.payDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.payDate" @input="menuDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="9" lg="9">
            <v-row>
              <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                <v-subheader>Kasbon</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="listDriverKasbon"
                  item-text="kasbonNumber"
                  return-object
                  v-model="kasbon"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.kasbonNumber"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.oldKasbon"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="2" lg="2" class="py-0">
                <v-btn color="success" @click="addKasbon">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.paymentMethod"
                  :items="listPaymentMethod"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.paymentMethod === 'Transfer'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="accountNumber"
                  item-value="id"
                  v-model="form.accountNumberId"
                  :items="listAccountNumber"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="account"
                  :items="listBank"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.banks.length > 0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <account-list :items="form.banks" @deleteItem="deleteItem"></account-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.bankCharge"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Kasbon <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-kasbon
      :item="item"
      :index="index"
      :dialog="dialog"
      @save="close"
      @deleteItem="deleteItem"
    ></dialog-kasbon>
  </form>
</template>

<script>
import AccountList from "@/components/AccountList";
import DialogKasbon from "@/components/DialogKasbon";

export default {
  name: "form-payment-general-kasbon",
  components: {
    "dialog-kasbon": DialogKasbon,
    "account-list": AccountList,
  },
  props: {
    form: Object,
    listDriverKasbon: Array,
    listBank: Array,
    listAccountNumber: Array,
  },
  data: () => ({
    menuDate: false,
    dialog: false,
    item: {},
    index: -1,
    account: null,
    kasbon: null,
    listPaymentMethod: ["Giro", "Cash", "Transfer"],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Kasbon Number",
        value: "kasbonNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Kasbon Name",
        value: "kasbonName",
        sortable: false,
        divider: true,
      },
      {
        text: "Division",
        value: "division",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Amount",
        value: "totalAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    totalAmount() {
      let total = 0;
      this.form.items.map(x => {
        total = total + x.amount;
      });
      return total;
    },
  },

  watch: {},

  methods: {
    update() {
      this.form.totalAmount = this.totalAmount;
      this.$store.dispatch("paymentGeneralKasbon/update", this.form);
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.items.splice(index, 1);
        this.dialog = false;
      }
    },
    close() {
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    addKasbon(item) {
      this.item = item;
      this.index = this.form.items.map(x => x).indexOf(item);
      this.dialog = true;
      this.form.items.push(item);
    },
    addAccount(item) {
      this.form.bank.push(item);
    },
  },
};
</script>

<style></style>
